<template>
    <div>
        <div class="headertext">Noumenon Games privacy policy</div>
        <div class="regulartext">We all just want to play games and have fun, preferably without being spied on.</div>
        <br />
        <div class="headertext">Data Collected</div>
        <div class="regulartext">Our games collect no data. Snakebird and Snakebird Primer is built in Unity, but all analytics & data collection options are turned off.</div>
        <br />
        <div class="regulartext">You will note that our games require no special permissions or access to any personal data of any kind.</div>
        <br />
        <div class="headertext">Games covered</div>
        <div class="regulartext">All our released games are covered by this privacy policy, Snakebird, Snakebird Primer and Nimbus</div>
        <br />
        <div class="headertext">Contact</div>
        <div class="regulartext">If you need to get in contact with us regarding our privacy policy send an email to <a href="mailto:info@noumenongames.com">info@noumenongames.com</a></div>
    </div>
</template>
<script>
export default {
    methods:
    {
    }
}
</script>